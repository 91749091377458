import "./App.scss";
import { Logo } from "@sikaeducation/ui";

export default function App() {
  return (
    <div className="App">
      <header>
        <Logo size="large" />
        <p id="tagline">Engineering Education</p>
      </header>
      <main>
        <div id="index">
          <p>
            We’re passionate about education and quality. That’s why our
            software training programs focus on people being able to
            consistently demonstrate new skills. We’re building some new courses
            right now. What do you want to learn?
          </p>
          <p>
            Tell us at{" "}
            <a href="mailto:learn@sikaeducation.com">learn@sikaeducation.com</a>
            .
          </p>
        </div>
      </main>
      <footer id="Footer">
        <small>©2022, Sika Education LLC</small>
      </footer>
    </div>
  );
}
