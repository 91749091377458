import { createRoot } from "react-dom/client";
import App from "./App";

import "@sikaeducation/ui/components.css";
import "@sikaeducation/ui/reset";
import "@sikaeducation/ui/styles";

const root = createRoot(document.getElementById("root")!);

root.render(<App />);
